@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

.storeSwitcherButton {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
}

.storeSwitcherButtonSimple {
    align-items: center;
    display: flex;
    gap: 0.7rem;
    text-transform: uppercase;
}

.storeSwitcherFlag {
    background-color: colors.$color--white;
    border: 1px solid colors.$border-color--primary;
    border-radius: 100%;
    height: 1rem;
    overflow: hidden;
    width: 1rem;
}
