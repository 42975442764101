@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';
@use '@/styles/resources/z-index' as zIndexes;

.root {
    bottom: 0;
    display: grid;
    grid-column: 3;
    left: 0;
    min-width: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: visibility 0.25s,
    opacity 0.25s ease;
    visibility: hidden;
    width: 100%;

    @include responsive.media('>tablet') {
        height: min-content;
        opacity: 1;
        position: sticky;
        top: 2rem;
        visibility: visible;
    }
}

.root_active {
    composes: root_active from '@/components/Mask/mask.module.scss';

    @include responsive.media('>tablet') {
        display: none;
    }
}

.rootIsOpen {
    opacity: 1;
    visibility: visible;
    z-index: zIndexes.$index--modal;

    .summaryContainer {
        transform: translateX(0);
    }
}

.title {
    @include mixins.heading-3();
}

.isReviewStep {
    composes: rootIsOpen;
    position: static;
    z-index: zIndexes.$index--review-step;

    .summaryContainer,
    .summaryContent {
        position: static;
    }

    .summaryContainer {
        max-width: 100%;
    }

    .summaryCloseButton {
        display: none;
    }
}

.summaryContainer {
    bottom: 0;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.25s ease-in-out;
    width: 100%;

    @include responsive.media('<=tablet') {
        max-width: 22.5rem;
    }

    @media (max-width: 30rem) {
        max-width: 100%;
    }

    @include responsive.media('>tablet') {
        border-radius: variables.$border-radius;
        position: relative;
        transform: translateX(0);
    }
}

.summaryContent {
    @include responsive.media('<=tablet') {
        @include mixins.card();
        align-content: flex-start;
        bottom: 0rem;
        display: grid;
        left: 0rem;
        overflow: auto;
        position: fixed;
        right: 0rem;
        top: 0rem;
    }
}

.summaryCard {
    @include responsive.media('>tablet') {
        @include mixins.card();
    }
}

.summaryCloseButton {
    @include mixins.trigger-icon();
    margin: 0 auto 0.5rem 0;

    @include responsive.media('>tablet') {
        display: none;
    }
}

.summaryButton {
    border-radius: 0;
    left: -3.4rem;
    padding: 1rem 0.5rem;
    position: absolute;
    top: 0;
    width: 3.4rem;
}

.summaryButtonIcon {
    height: 1.3rem;
    transform: scale(-1, 1);
    width: 1.3rem;
}

.summaryWrapper {
    border-top: 1px solid colors.$border-color--tertiary;
    padding-top: 1rem;
}

.summaryTitle {
    @include mixins.heading-2();
    color: var(--color--gray-800);
    text-transform: none;

    &Wrapper {
        align-items: center;
        border-bottom: 1px solid colors.$border-color--tertiary;
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
    }
}

.summarySubtitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;

    &Count {
        font-weight: fonts.$font-weight--bold;
        text-transform: uppercase;
    }
}

.summaryTitleLink {
    align-items: center;
    display: flex;
    @include mixins.link-underline(colors.$text-color--gray, colors.$text-color--gray, $hasUnderline: false);
}

.summaryTitleLinkIcon {
    color: currentColor;
    height: 0.8rem;
    margin-right: 0.4rem;
}
