@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

$gap: 0.4rem;

.root {
    display: flex;
    flex-wrap: wrap;
    gap: 0 $gap;
}

.maxPrice {
    align-items: center;
    display: inline-flex;

    &::before {
        content: '—';
        display: inline-block;
        margin-right: $gap;
    }
}

.oldPrice {
    color: colors.$text-color--lighter;
    font-weight: normal;
    text-decoration: line-through;
}
