@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

$borderHeight: 1px;
$selectHeight: 2.5rem;
$transitionTime: 0.2s;

.root {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    &.spanSingleColumn {
        @include responsive.media('>tablet') {
            grid-column: span 1;
        }
    }
}

.wrapper {
    position: relative;
}

.chevronIcon {
    pointer-events: none;
    position: absolute;
    right: 0.85rem;
    top: 0.75rem;
    transform: rotate(90deg);
    user-select: none;
}

.label {
    color: colors.$text-color--gray;
    font-size: 0.75rem;
    left: 0;
    letter-spacing: 0.5px;
    position: absolute;
    touch-action: none;
    transform: translate3d(1rem, -0.55rem, 0);
    transition: $transitionTime;
}

.supplement {
    font-style: italic;
    margin-right: 1rem;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    user-select: none;
}

.fieldNotice {
    display: block;
    font-size: 0.75rem;
    margin: 0.75rem 0;
}

.fieldset {
    border-color: colors.$border-color--black;
    border-radius: variables.$border-radius;
    border-style: solid;
    border-width: $borderHeight;
    inset: -0.55rem 0 0;
    margin: 0;
    min-width: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    text-align: left;
}

.legend {
    display: block;
    float: unset;
    font-size: 0.75rem;
    letter-spacing: 0.5px;
    margin-left: 0.45rem;
    max-width: 100%;
    padding: 0;
    pointer-events: none;
    transform: translate3d(0, 0.1rem, 0);
    -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
    user-select: none;
    visibility: hidden;
    white-space: nowrap;
    width: auto;

    &Hidden {
        width: 0;

        span {
            display: inline-block;
            visibility: hidden;
            width: 0;
        }
    }

    span {
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.select {
    appearance: none; // Replaced with an icon for better positioning
    background-color: transparent;
    border-radius: variables.$border-radius;
    height: $selectHeight;
    outline: none;
    padding: 0.85rem 0.85rem 0.1rem;
    transition: border 0.16s ease-in-out;
    width: 100%;

    &.isPadded {
        padding-right: 5.5rem;
    }

    &.isCounter {
        padding: 0 2rem 0 1rem;
    }

    &::placeholder {
        display: none;
    }

    &:focus {
        + .label {
            color: colors.$color--cornflower-blue;
        }
    }

    &:-webkit-autofill {
        transition: all 0.16s ease-in-out;

        &.error {
            -webkit-animation-delay: 1s !important; /* Safari support - any positive time runs instantly */
            -webkit-animation-fill-mode: both !important;
            -webkit-animation-name: autofill !important;
            -webkit-background-clip: text !important;
        }
    }

    &.error {
        ~ fieldset {
            background-color: rgba(colors.$message-text--error, 0.1) !important;
            border-color: colors.$message-text--error;
        }

        + .label {
            color: colors.$status-color--error;
        }
    }

    &:disabled {
        opacity: 0.4;

        ~ fieldset,
        ~ .chevronIcon,
        ~ .supplement {
            opacity: 0.5;
        }

        + .label {
            opacity: 0.5;
        }
    }
}

@keyframes autofill {
    0%,
    100% {
        background: transparent;
    }
}
