@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';
@use '@/styles/resources/mixins';
@use "sass:color";

.error {
    .button {
        border-color: colors.$status-color--error;
    }
}

.label {
    align-items: center;
    display: grid;
    font-weight: fonts.$font-weight--bold;
    gap: 1rem;
    position: relative;

    @include responsive.media('>tablet') {
        display: flex;
    }
}

.button {
    border: 2px solid colors.$text-color--gray;
    border-radius: variables.$border-radius;
    font-size: 0.875rem;
    padding: 0.625rem 1.5rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;

    @include responsive.media('>tablet') {
        width: auto;
    }

    &:hover {
        background-color: color.adjust(colors.$brand-color--secondary, $lightness: -5%);
    }
}

.input {
    :global {
        @include mixins.a11y-visually-hidden();
    }
}
