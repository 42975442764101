@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.list {
    display: grid;
    list-style-type: none;
    overflow: auto;
    padding-top: 1rem;

    @include responsive.media('>tablet') {
        max-height: 20.6rem;
    }
}

.listSkeleton {
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;

    span {
        display: block;
        height: 100%;
    }
}

.listSkeletonLine {
    flex: 1 1 auto;
    width: 100%;
}
