@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/fonts';

.telephone {
    align-items: center;
    display: flex;
}

.telephoneIcon {
    margin-right: 0.5rem;
}

.cancelDeleteButton {
    background-color: white;
}

.defaultBadgeWrapper {
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    left: -1rem;
    position: relative;
    row-gap: 0.5rem;
}

.defaultBadge {
    background-color: colors.$color--gray-lighter;
    font-size: 0.8em;
    font-weight: fonts.$font-weight--bold;
    min-width: 8.5rem;
    padding: 0.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    width: fit-content;
}

.titleContainer {
    color: colors.$text-color--primary;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.contentContainer {
    display: grid;
    font-size: 0.95em;
    hyphens: auto;
    margin-left: 0.5rem;
    row-gap: 0.1rem;
    word-break: break-word;

    > :first-of-type {
        font-size: 1.1em;
        font-weight: fonts.$font-weight--bold;
    }
}

.actionContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
}

.editAddressIcon {
    height: 0.75rem;
    width: 0.75rem;
}

.removeButton {
    align-items: center;
    display: flex;
    gap: 0.3rem;
    @include mixins.link-underline(colors.$text-color--primary, colors.$text-color--primary);

    &:focus {
        outline: none;
    }
}
