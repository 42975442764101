@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';

.imageRoot {
    height: 100%;
}

.image {
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.productInfo {
    align-items: center;
    display: grid;
    font-size: 0.75rem;
    grid-gap: 0.75rem;
    grid-template-columns: 4rem 1fr;
    padding-bottom: 1.3rem;
}

.imageWrapper {
    display: flex;
    height: 4rem;
    width: 4rem;
}

.productName {
    display: block;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 0.25rem;
}

.productNameLink {
    @include mixins.link-underline(colors.$text-color--primary, colors.$text-color--primary);
}

.productPrice {
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: fonts.$font-weight--medium;
    margin-bottom: 0.25rem;
}

.stockStatus {
    color: colors.$text-color--red;
    font-weight: fonts.$font-weight--medium;
    margin-top: 0.25rem;
    text-transform: uppercase;
}

.quantity {
    margin-top: 0.25rem;
}
