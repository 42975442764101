@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';

.root {
    font-size: 0.875rem;

    li {
        margin-top: 0.5rem;
    }

    li:first-child {
        display: none;
    }

    a {
        @include mixins.link-underline($baseColor: colors.$text-color--primary, $hoverColor: colors.$text-color--primary, $hasUnderline: true);
    }
}

//New design
.newRoot {
    composes: root;

    li:nth-of-type(2) {
        margin-top: 0;
    }

    a {
        @include vars.inline-link;
    }
}
