@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.contentWrapper {
    margin-bottom: 20rem;

    @include responsive.media('>mobile') {
        margin-bottom: 40rem;
    }
}
