@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.listItems {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.7rem;
    gap: 1.2rem;
    padding-top: 1rem;
    text-align: center;
}

.listItem {
    align-content: flex-start;
    display: grid;
    gap: 1rem;
    width: 6rem;
}

.listItemIcon {
    height: 4rem;
    margin: 0 auto;
    stroke-width: 2;
    width: 4rem;
}

// New design
.title {
    color: var(--color--gray-800);
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.newListItems {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    text-align: center;
}

.newListItemIcon {
    height: 4.5rem;
    margin: 0 auto;
    stroke-width: 2;
    width: 4.5rem;
}
